import React, { useContext } from "react";

import _ from "app/lang";
import { Site, User } from "app/models";
import { pageContext, PartialContext } from "app/page/ContextProvider";

import DrawerMenu, { DrawerMenuItem } from "./DrawerMenu";

export function getMenuItems(context: PartialContext) {
    if (!context.site) {
        return [];
    }

    const user = new User(context);
    const site = new Site(context.site);
    const legacyUrl = "/site/:siteId";
    const fe2Url = "/v2/cloud/:cloudId/site/:siteId";

    const items: DrawerMenuItem[] = [
        {
            icon: "dashboard",
            text: _("Dashboard"),
            href: `${legacyUrl}/dashboard`
        },
        {
            icon: "ignite_ap",
            text: _("Devices"),
            href: `${fe2Url}/devices`
        },
        {
            icon: "list_alt",
            text: _("Activity"),
            href: `${legacyUrl}/activity`
        },
        {
            icon: "signal_wifi_3_bar",
            text: _("Wireless Clients"),
            href: `${legacyUrl}/clients`
        },
    ];

    if (user.hasPermission("edit_site")) {
        items.push(
            {
                text: _("Manage")
            },
        );
    }

    items.push(
        {
            icon: "place",
            text: _("Maps"),
            subitems: [
                {
                    icon: "google_maps",
                    text: _("Google Map"),
                    href: `${legacyUrl}/google-map`
                },
                {
                    icon: "map",
                    text: _("Floor Maps"),
                    href: `${legacyUrl}/floor-maps`
                },
                {
                    icon: "device_hub",
                    text: _("Topology"),
                    href: `${fe2Url}/topology`
                }
            ]
        }
    );

    if (user.hasPermission("manage_licenses")) {
        items.push(
            {
                icon: "developer_board",
                text: _("Add-ons"),
                href: `${legacyUrl}/addons`
            }
        );
    }

    if (user.hasPermission("edit_site")) {
        items.push(
            {
                icon: "edit_attributes",
                text: _("Site Properties"),
                href: `${fe2Url}/edit`
            },
            {
                icon: "notifications",
                text: _("Notifications"),
                href: `${fe2Url}/notification-settings`
            }
        );
    }

    if (site.configEnabled && user.hasPermission("view_config")) {
        const configItem: DrawerMenuItem = {
            icon: "build",
            text: _("Configuration"),
            subitems: [
                {
                    icon: "settings",
                    text: _("General"),
                    href: `${legacyUrl}/config#general-locale`
                },
                {
                    icon: "wifi5",
                    text: _("Wi-Fi 5"),
                    href: `${legacyUrl}/config#wifi5-wireless`
                },
                {
                    icon: "wifi6andabove",
                    text: _("Wi-Fi 6 & Newer"),
                    href: `${legacyUrl}/config#wifi6-wireless`
                },
                {
                    icon: "metrolinq",
                    text: _("MetroLinq"),
                    href: `${legacyUrl}/config#ml-wireless`
                },
                {
                    icon: "glinq",
                    text: _("GLinq"),
                    href: `${legacyUrl}/config#glinq-wireless`
                },
                {
                    icon: "switch",
                    text: _("Switch"),
                    href: `${legacyUrl}/config#switch-security`
                },
                {
                    icon: "metrolinq",
                    text: _("MLTG"),
                    href: `${legacyUrl}/config#terragraph-network-planning`
                }
            ]
        };

        if (context.enabledConfigTabs) {
            if (context.enabledConfigTabs.includes("SDWAN")) {
                configItem.subitems.push(
                    {
                        icon: "switch",
                        text: _("SDWAN"),
                        href: `${legacyUrl}/config#sdwan-group`
                    }
                );
            }
        }

        items.splice(2, 0, configItem);
    }

    return items;
}

export default function SiteMenu() {
    const context = useContext(pageContext);
    const items = getMenuItems(context);

    return <DrawerMenu items={items} />;
}
