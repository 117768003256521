import React, { useContext, useEffect, useState } from "react";

import DialogActions from "@material-ui/core/DialogActions";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Button from "app/Button";
import Dialog from "app/Dialog";
import request from "app/http";
import _ from "app/lang";
import { CloudContext, pageContext } from "app/page/ContextProvider";
import storage from "app/storage";
import { disableAlert, shouldShowAlert } from "app/userAlertStore";

const INTRO_CLOSE_POSTPONE = 24 * 3600 * 1000;
const INTRO_SKIP_KEY = "advertisement-aprecomm-skip";
const INTRO_ALERT_KEY = "ADVERTISEMENT";
const useStyles = makeStyles((theme: Theme) => createStyles({
    btn: {
        color: "white",
        background: theme.colors.primary.main,
        "&:hover": {
            color: theme.colors.primary.main,
        },
    },
}));

export default function Advertisement() {
    const classes = useStyles();
    const context = useContext(pageContext) as CloudContext;
    const [advertisementOpen, setAdvertisementOpen] = useState<boolean>(false);
    const shouldShowAdvertisement = (
        context.environment !== "integration" &&
        context.cloud?.enable_advertisement &&
        storage.getItem<number>(INTRO_SKIP_KEY, 0) < Date.now() - INTRO_CLOSE_POSTPONE
    );
    const [currentSlide, setCurrentSlide] = useState<number>(0);

    const [videos, setVideos] = useState<string[]>([]);

    useEffect(() => {
        async function getVideos() {
            if (shouldShowAdvertisement) {
                const response = await request({
                    url: `/apiv2/cloud/${context.cloud.id}/addons/advertisement`,
                    method: "GET",
                });
                setVideos(response);
            }
        }
        getVideos();
    }, [shouldShowAdvertisement]);


    useEffect(() => {
        if (!shouldShowAdvertisement) {
            return;
        }

        (async function () {
            const shouldShowIntro = await shouldShowAlert(INTRO_ALERT_KEY);

            setAdvertisementOpen(shouldShowIntro);
        }());

    }, [shouldShowAdvertisement]);

    function handleClose() {
        setAdvertisementOpen(false);
        storage.setItem(INTRO_SKIP_KEY, Date.now());
    }

    function handleDisable() {
        setAdvertisementOpen(false);
        disableAlert(INTRO_ALERT_KEY);
    }

    function nextSlide() {
        setCurrentSlide((prevIndex) => (prevIndex + 1) % videos.length);
    }

    return (
        <Dialog
            open={advertisementOpen}
            title={_("Freemium")}
            onClose={handleClose}
            onExited={() => null}
        >
            <iframe
                id="ytplayer"
                src={videos[currentSlide]}
                frameBorder="0"
                allowFullScreen={true}
                style={{
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginBottom: "8px",
                    width: "580px",
                    height: "340px"
                }}
            />
            <DialogActions>
                <Button onClick={handleClose}>{_("Remind Me Later")}</Button>
                <Button onClick={handleDisable}>{_("Do Not Show Again")}</Button>
                {videos.length > 1 && <Button onClick={nextSlide} className={classes.btn}>Next Message</Button>}
            </DialogActions>
        </Dialog>
    );
}
